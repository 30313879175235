// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-index-jsx": () => import("./../../../src/pages/aboutus/index.jsx" /* webpackChunkName: "component---src-pages-aboutus-index-jsx" */),
  "component---src-pages-advertising-index-jsx": () => import("./../../../src/pages/advertising/index.jsx" /* webpackChunkName: "component---src-pages-advertising-index-jsx" */),
  "component---src-pages-agreement-index-jsx": () => import("./../../../src/pages/agreement/index.jsx" /* webpackChunkName: "component---src-pages-agreement-index-jsx" */),
  "component---src-pages-complaints-consumers-index-jsx": () => import("./../../../src/pages/complaints/consumers/index.jsx" /* webpackChunkName: "component---src-pages-complaints-consumers-index-jsx" */),
  "component---src-pages-complaints-copyright-index-jsx": () => import("./../../../src/pages/complaints/copyright/index.jsx" /* webpackChunkName: "component---src-pages-complaints-copyright-index-jsx" */),
  "component---src-pages-complaints-index-jsx": () => import("./../../../src/pages/complaints/index.jsx" /* webpackChunkName: "component---src-pages-complaints-index-jsx" */),
  "component---src-pages-complaints-trademark-index-jsx": () => import("./../../../src/pages/complaints/trademark/index.jsx" /* webpackChunkName: "component---src-pages-complaints-trademark-index-jsx" */),
  "component---src-pages-helpcenter-index-jsx": () => import("./../../../src/pages/helpcenter/index.jsx" /* webpackChunkName: "component---src-pages-helpcenter-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-index-jsx": () => import("./../../../src/pages/information/index.jsx" /* webpackChunkName: "component---src-pages-information-index-jsx" */),
  "component---src-pages-partner-index-jsx": () => import("./../../../src/pages/partner/index.jsx" /* webpackChunkName: "component---src-pages-partner-index-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("./../../../src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-product-features-index-jsx": () => import("./../../../src/pages/product-features/index.jsx" /* webpackChunkName: "component---src-pages-product-features-index-jsx" */),
  "component---src-pages-question-index-jsx": () => import("./../../../src/pages/question/index.jsx" /* webpackChunkName: "component---src-pages-question-index-jsx" */),
  "component---src-pages-register-index-jsx": () => import("./../../../src/pages/register/index.jsx" /* webpackChunkName: "component---src-pages-register-index-jsx" */),
  "component---src-pages-shoprise-index-jsx": () => import("./../../../src/pages/shoprise/index.jsx" /* webpackChunkName: "component---src-pages-shoprise-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */),
  "component---src-pages-theme-index-jsx": () => import("./../../../src/pages/theme/index.jsx" /* webpackChunkName: "component---src-pages-theme-index-jsx" */)
}

